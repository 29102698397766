import React from 'react'
import { StoreQuantityUpdate } from '../../components/storeQuantityUpdate'
const AddArticlesQuantity = () => {
    const filterStore = <StoreQuantityUpdate />
    return (
        <div>
            {filterStore}
        </div>
    )
}
export default AddArticlesQuantity;